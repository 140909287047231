import { createInstance } from '@amplitude/analytics-browser';
import { computed } from 'vue';

const environment = computed(() => {
  if (typeof window !== 'undefined') {
    const { host } = window.location;

    if (host.includes("localhost")) return "local";

    if (host.includes("dev")) return "development";
  }

  return "production";
});

const apiKey = computed(() => {
  if (environment.value === "production") return import.meta.env.VITE_AMPLITUDE_API_KEY || "";

  if (environment.value === "development") return import.meta.env.VITE_AMPLITUDE_API_KEY_DEV || "";

  return "";
});

export default defineNuxtPlugin(async (nuxtApp) => {
  if (!apiKey.value) return;

  const amplitude = createInstance();
  amplitude.init(apiKey.value, undefined, {
    autocapture: {
      attribution: true,
      pageViews: true,
      sessions: true,
      formInteractions: true,
      fileDownloads: true,
      elementInteractions: true,
    },
  });

  nuxtApp.provide('amplitude', amplitude);
});

declare module '#app' {
  interface NuxtApp {
    $amplitude: ReturnType<typeof createInstance>;
  }
}