import { defineNuxtPlugin } from '#app'
import Intercom from '@intercom/messenger-js-sdk'

const environment = computed(() => {
  if (typeof window !== 'undefined') {
    const { host } = window.location;

    if (host.includes("localhost")) return "local";

    if (host.includes("dev")) return "development";
  }

  return "production";
});

const appId = computed(() => {
  if (environment.value === "production") return import.meta.env.VITE_INTERCOM_APP_ID || "";

  return "";
});

export default defineNuxtPlugin(async (nuxtApp) => {
  if (!appId.value) return;

  Intercom({
    app_id: appId.value,
    action_color: "#074033",
    background_color: "#F8FAF2",
    vertical_padding: 20,
    horizontal_padding: 20,
  });

  nuxtApp.provide('intercom', Intercom);
})
