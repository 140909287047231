import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBlogView, LazyBreadcrumps, LazyBreadcrumpsCategory, LazyCard, LazyComponentBlocksContentCards, LazyComponentBlocksContentForm, LazyComponentBlocksContentHtml, LazyComponentBlocksContentImage, LazyComponentBlocksFaq, LazyComponentBlocksHero, LazyComponentBlocksPdf, LazyComponentBlocksPricing, LazyComponentBlocksPromo, LazyComponentBlocksQuote, LazyComponentBlocksSlider, LazyComponentBlocksStepByStep, LazyComponentBlocksTestimonials, LazyComponentBlocksTrustedBy, LazyComponentSharedRedirect, LazyComponentSharedSeo, LazyComponentSharedTabs, LazyCookie, LazyFooter, LazyHeader, LazyIcon, LazyImageBcgTmp, LazyImageTmp, LazyNbButton, LazyPageView, LazyPricingBlock, LazyZohoForm, LazyZohoFormNewsletter, LazyZohoFormSLGuide, LazyCookieClientOnlyPrerender, LazyCookieControl, LazyCookieIframe, LazyProseA, LazyProseBlockquote, LazyProseCode, LazyProseEm, LazyProseH1, LazyProseH2, LazyProseH3, LazyProseH4, LazyProseH5, LazyProseH6, LazyProseHr, LazyProseImg, LazyProseLi, LazyProseOl, LazyProseP, LazyProsePre, LazyProseScript, LazyProseStrong, LazyProseTable, LazyProseTbody, LazyProseTd, LazyProseTh, LazyProseThead, LazyProseTr, LazyProseUl } from '#components'
const lazyGlobalComponents = [
  ["BlogView", LazyBlogView],
["Breadcrumps", LazyBreadcrumps],
["BreadcrumpsCategory", LazyBreadcrumpsCategory],
["Card", LazyCard],
["ComponentBlocksContentCards", LazyComponentBlocksContentCards],
["ComponentBlocksContentForm", LazyComponentBlocksContentForm],
["ComponentBlocksContentHtml", LazyComponentBlocksContentHtml],
["ComponentBlocksContentImage", LazyComponentBlocksContentImage],
["ComponentBlocksFaq", LazyComponentBlocksFaq],
["ComponentBlocksHero", LazyComponentBlocksHero],
["ComponentBlocksPdf", LazyComponentBlocksPdf],
["ComponentBlocksPricing", LazyComponentBlocksPricing],
["ComponentBlocksPromo", LazyComponentBlocksPromo],
["ComponentBlocksQuote", LazyComponentBlocksQuote],
["ComponentBlocksSlider", LazyComponentBlocksSlider],
["ComponentBlocksStepByStep", LazyComponentBlocksStepByStep],
["ComponentBlocksTestimonials", LazyComponentBlocksTestimonials],
["ComponentBlocksTrustedBy", LazyComponentBlocksTrustedBy],
["ComponentSharedRedirect", LazyComponentSharedRedirect],
["ComponentSharedSeo", LazyComponentSharedSeo],
["ComponentSharedTabs", LazyComponentSharedTabs],
["Cookie", LazyCookie],
["Footer", LazyFooter],
["Header", LazyHeader],
["Icon", LazyIcon],
["ImageBcgTmp", LazyImageBcgTmp],
["ImageTmp", LazyImageTmp],
["NbButton", LazyNbButton],
["PageView", LazyPageView],
["PricingBlock", LazyPricingBlock],
["ZohoForm", LazyZohoForm],
["ZohoFormNewsletter", LazyZohoFormNewsletter],
["ZohoFormSLGuide", LazyZohoFormSLGuide],
["CookieClientOnlyPrerender", LazyCookieClientOnlyPrerender],
["CookieControl", LazyCookieControl],
["CookieIframe", LazyCookieIframe],
["ProseA", LazyProseA],
["ProseBlockquote", LazyProseBlockquote],
["ProseCode", LazyProseCode],
["ProseEm", LazyProseEm],
["ProseH1", LazyProseH1],
["ProseH2", LazyProseH2],
["ProseH3", LazyProseH3],
["ProseH4", LazyProseH4],
["ProseH5", LazyProseH5],
["ProseH6", LazyProseH6],
["ProseHr", LazyProseHr],
["ProseImg", LazyProseImg],
["ProseLi", LazyProseLi],
["ProseOl", LazyProseOl],
["ProseP", LazyProseP],
["ProsePre", LazyProsePre],
["ProseScript", LazyProseScript],
["ProseStrong", LazyProseStrong],
["ProseTable", LazyProseTable],
["ProseTbody", LazyProseTbody],
["ProseTd", LazyProseTd],
["ProseTh", LazyProseTh],
["ProseThead", LazyProseThead],
["ProseTr", LazyProseTr],
["ProseUl", LazyProseUl],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
