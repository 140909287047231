import revive_payload_client_4sVQNw7RlN from "/mnt/agent/work/a3eaf6f60f6858aa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/mnt/agent/work/a3eaf6f60f6858aa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/mnt/agent/work/a3eaf6f60f6858aa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/mnt/agent/work/a3eaf6f60f6858aa/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/mnt/agent/work/a3eaf6f60f6858aa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/mnt/agent/work/a3eaf6f60f6858aa/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/mnt/agent/work/a3eaf6f60f6858aa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/mnt/agent/work/a3eaf6f60f6858aa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/mnt/agent/work/a3eaf6f60f6858aa/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/mnt/agent/work/a3eaf6f60f6858aa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_C2QvvtgUo3 from "/mnt/agent/work/a3eaf6f60f6858aa/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import plugin_tbFNToZNim from "/mnt/agent/work/a3eaf6f60f6858aa/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_UYiXMU8ZyN from "/mnt/agent/work/a3eaf6f60f6858aa/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_eTVJQYlCmx from "/mnt/agent/work/a3eaf6f60f6858aa/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import intercom_client_vr7NPky0pK from "/mnt/agent/work/a3eaf6f60f6858aa/plugins/intercom.client.ts";
import amplitude_client_a5NDjvTWdZ from "/mnt/agent/work/a3eaf6f60f6858aa/plugins/amplitude.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_C2QvvtgUo3,
  plugin_tbFNToZNim,
  plugin_client_UYiXMU8ZyN,
  plugin_eTVJQYlCmx,
  intercom_client_vr7NPky0pK,
  amplitude_client_a5NDjvTWdZ
]